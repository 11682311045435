<template>
  <div class='wameed-dashboard-page-content'>
    <page-header
        :title="isUpdate?$t('talqah_business.branches.update_btn'):$t('talqah_business.branches.new_btn')"
        :paths="[
          {
            title:$t('nav.talqah_business.title'),
          },
          {
            title:$t('nav.talqah_business.branches.title'),
            link:'talqah-business-providers'
          },
        ]"
    />
    <section class='wameed-dashboard-page-content_body products_create'>
      <b-row class='px-2'>
        <b-col lg='12' md='12' class='px-0'
               :key="key">
          <b-card no-body class='wameed-card'>
            <b-card-header class="align-items-center tab-title">
              <h5 class='text-med-20 text-font-main mb-0'>
                {{ $t('talqah_business.branches.form.title') }}
              </h5>
            </b-card-header>
            <wameed-form @onSubmit='submit'>
              <div slot-scope='{ invalid }'>
                <b-card-body
                    class='position-relative justify-content-between px-5'
                >
                  <!-- form -->
                  <div class='d-flex flex-wrap'>
                    <b-col cols='12' md='4'>
                      <text-input
                          id='name_ar'
                          v-model='form.name_ar'
                          :label="$t('talqah_business.branches.form.name_ar')"
                          rules='required'
                          :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols='12' md='4'>
                      <text-input
                          id='name_en'
                          v-model='form.name_en'
                          :label="$t('talqah_business.branches.form.name_en')"
                          rules='required'
                          :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols='12' md='4'>
                      <text-input
                          id='phone'
                          v-model='form.phone'
                          :label="$t('talqah_business.branches.form.phone')"
                          rules='required'
                          :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>

                    <b-col cols='12' md='6'>
                      <wameed-dropdown
                        :searchable="true"
                        :loadingMore="$t('common.loadingMore')"
                        :no_options_search="$t('common.no_options_search')"
                        v-model='provider'
                        label="المزود"
                        placeholder="المزود"
                        variant='disable'
                        :items="getProviders"
                        class="wameed_dropdown"
                        title="name"
                        rules='required'

                        :no_options="$t('common.no_options')"
                      />
                    </b-col>

                    <b-col cols='12' md='6'>
                      <wameed-dropdown
                        :searchable="true"
                        :loadingMore="$t('common.loadingMore')"
                        :no_options_search="$t('common.no_options_search')"
                        v-model='city'
                        label="المدينة"
                        placeholder="المدينة"
                        variant='disable'
                        :items="cities"
                        class="wameed_dropdown"
                        title="name"
                        rules='required'

                        :no_options="$t('common.no_options')"
                      />
                    </b-col>

                    <b-col cols='12' md='6'>
                      <wameed-dropdown
                        :no_options_search="$t('common.no_options_search')"
                        v-model='zone'
                        :label="$t('talqah_business.branches.form.zone')"
                        :placeholder="$t('talqah_business.branches.form.zone')"
                        variant='disable'
                        :items="zones"
                        class="wameed_dropdown"
                        title="name"
                        rules='required'
                        :no_options="$t('common.no_options')"
                      />
                    </b-col>

                    <b-col cols='12' md='6'>
                      <div @click='showMapModal = true'>
                        <text-input
                          id='map-address'
                          v-model='form.latlng'
                          readonly
                          name='location'
                          rules='required'
                          :label="$t('form.address.geoLocation')"
                          :placeholder="$t('form.address.geoLocation_placeholder')"
                          append-class='append-btn append-gray'
                          is-append
                          :append-text="$t('btn.add')"
                        />
                      </div>
                    </b-col>

                    <b-col md="12">
                      <text-area
                        v-model="form.address_description"
                        :label="$t('talqah_business.branches.form.description')"
                        :placeholder="$t('form.text.placeholder')"
                        rows="1"
                      />
                    </b-col>

                    <b-col cols='12' md='4' class="mt-5">
                      <b-form-checkbox v-model="form.is_active" name="check-button" switch>
                        مفعل
                      </b-form-checkbox>
                    </b-col>

                    <b-col cols='12' md='4' class="z-100">
<!--                      <wameed-image-cropper-input
                          :label="$t('users.vendor.form.profile_bg')"
                          :placeholder="$t('form.image.placeholder')"
                          :uploaded-image="form.temp_profile_bg"
                          :get-object="true"
                          :fixed="true"
                          @changed="uploadBackgroundImage"
                          :width="323"
                          :height="180"
                      />-->
<!--                      <wameed-input-file-upload-->
<!--                          fileType='images'-->
<!--                          srcName='url'-->
<!--                          field-classes='w-100 z-100'-->
<!--                          functionType='front'-->
<!--                          :value="form.temp_profile_bg&&form.temp_profile_bg.length>0?form.temp_profile_bg[0].url:''"-->
<!--                          :uploadedFiles='form.temp_profile_bg'-->
<!--                        -->
<!--                          :input-placeholder=""-->
<!--                          :input-append-text="$t('form.image.browse')"-->
<!--                          @changed='uploadBackgroundImage'-->
<!--                          @deleteImage='deleteBackgroundImage'-->
<!--                      />-->
                    </b-col>

<!--                    <b-col cols='12' md='4'>
                      <wameed-dropdown
                        v-model='form.payment_type'
                        :label="$t('users.vendor.form.contract_type')"
                        :placeholder="$t('users.vendor.form.contract_type')"
                        variant='disable'

                        :items="payment_types"
                        class='wameed_dropdown'
                        title='name'
                        rules='required'
                        :no_options="$t('common.no_options')"
                      />
                    </b-col>-->

<!--                    <b-col cols='12' md='4'>
                      <TextInput
                          v-model='form.talqah_commission'
                          field-classes='w-100'
                          :label="$t('users.vendor.form.talqah_commission')"
                          :placeholder="$t('form.text.placeholder')"
                          is-append
                          type='number'
                          rules='required'
                          append-text='%'
                      />
                    </b-col>-->
                  </div>


                </b-card-body>
                <b-card-footer class='px-4 py-3'>
                  <div class='d-flex flex-wrap justify-content-between'>
                    <div class='px-4 py-3'>
                      <wameed-btn
                          classes='  text-med-14 text-white rounded-10'
                          :title="isUpdate?$t('talqah_business.branches.update_btn'):$t('talqah_business.branches.new_btn')"
                          :disabled='invalid'
                          type='submit'
                          variant='main'
                      />
                    </div>

                    <div class='px-4 py-3'>
                      <wameed-btn
                          classes='  text-med-14 text-font-secondary rounded-10 '
                          :title="$t('common.cancel')"
                          type='button'
                          variant='gray'
                          @onClick='goBack'
                      />
                    </div>
                  </div>
                </b-card-footer>
              </div>
            </wameed-form>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <map-modal
        @setLocation='setLocation'
        :visible='showMapModal'
        @close='showMapModal = false'
    />


    <warning-modal
        variant='main'
        iconName='o-sent-icon'
        :visible='showSentModal'
        @close='goBack'
        @submitAction='goBack'
        :title="$t('users.vendor.sent.title')"
        :subTitle="$t('users.vendor.sent.subTitle')"
        :btnTitle="$t('common.OK')"
    />

  </div>
</template>

<script>

import {mapGetters} from 'vuex';

import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import {
  WameedBtn,
  WameedDropdown,
  WameedForm,
  WameedTextArea as TextArea,
  WameedTextInput as TextInput
} from 'wameed-ui/dist/wameed-ui.esm';
import MapModal from '@/components/mapModal';
import WarningModal from '@/components/WarningModal';
import WameedImageCropperInput from "@/components/wameed/WameedImageCropperInput.vue";
import { PaymentType } from '@/enums/paymentType.enum';

export default {
  components: {
    WameedImageCropperInput,
    WarningModal,
    MapModal,
    TextInput,
    TextArea,
    WameedBtn,
    WameedDropdown,
    WameedForm,
    PageHeader
  },
  data() {
    return {
      key: 1,
      showMapModal: false,
      showSentModal: false,
      city: null,
      provider: null,
      zones: [
        { name: "شمال", value: "zone1" },
        { name: "جنوب", value: "zone2" },
      ],
      cities: [],
      zone: null,
      form: {
        name_ar: '',
        name_en: '',
        phone: '',
        address_description: '',
        latlng: '',
        city_id: '',
        provider_id: '',
        city_zone: '',
        is_active: false
      }

    };
  },
  computed: {
    ...mapGetters({
      getCities: 'admin/talqahBusiness/cities/getData',
      getProviders: 'admin/talqahBusiness/providers/getData'
    }),


    showOrderTime() {
      return this.form.category.id !== 3;
    },

    maxOrderTime() {
      let value = this.form.min_order_time;
      if (value) {
        return '|min_value:' + value
      }
      return '';
    },

    tempProfileImageValue() {
      if (this.form.temp_profile_image.length > 0)
        return this.form.temp_profile_image[0].url
      return '';
    },
    tempBgImageValue() {
      if (this.form.temp_profile_bg && this.form.temp_profile_bg.length > 0)
        return this.form.temp_profile_bg[0].url
      return '';
    },
    isUpdate() {
      return (this.$route.name === 'talqah-business-branches-update' && this.$route.params.id);
    }
  },
  async beforeCreate() {

    await this.$store.dispatch('admin/talqahBusiness/providers/get');

    if (this.$route.name === 'talqah-business-branches-update' && this.$route.params.id) {
      this.$store.dispatch('admin/users/simpleDetail', {id: this.$route.params.id, type: 'vendors'}).then(async () => {

        await this.$store.dispatch('admin/users/getVendorClassification', this.userDetail.category_id);

        this.form = this.userDetail;
        if (this.userDetail.profile_image)
          this.form.temp_profile_image = {url: this.userDetail.profile_image,name: this.userDetail.profile_image};
        if (this.userDetail.profile_bg)
          this.form.temp_profile_bg = {url: this.userDetail.profile_bg,name:this.userDetail.profile_bg};

        this.form.category = this.getVendorCategories.find((cat) => cat.id === this.userDetail.category_id);
        this.form.classifications = this.getVendorClassification.filter((cat) => this.userDetail.classifications_ids.includes(cat.id));
      });

    } else {
      await this.$store.dispatch('admin/users/unsetDetail');
    }
  },


  methods: {
    uploadProfileImage(value) {
      this.key++;
      this.form.temp_profile_image = value;
    },
    deleteProfileImage() {
      this.key++;
      this.form.temp_profile_image = [];
    },
    uploadBackgroundImage(value) {
      this.key++;
      this.form.temp_profile_bg = value;
    },
    deleteBackgroundImage() {
      this.key++;
      this.form.temp_profile_bg = [];
    },
    setLocation(value) {
      // this.form.address = value.address;
      this.form.latlng = value.location;
      this.showMapModal = false;
    },
    goBack() {
      this.$router.push({name: 'talqah-business-branches'});
    },

    async onCategoryChange() {
      this.form.classifications = [];
      await this.$store.dispatch('admin/users/getVendorClassification', this.form.category.id);

    },
    submit() {
      this.form.city_id = this.city.id;
      this.form.city_zone = this.zone.value;
      this.form.provider_id = this.provider.id;
      console.log(this.form);
      if (this.$route.name === 'talqah-business-branches-update' && this.$route.params.id) {
        this.form.id = this.$route.params.id;
        this.$store.dispatch('admin/talqahBusiness/branches/update', this.form);
      } else {
        this.$store.dispatch('admin/talqahBusiness/branches/create', this.form).then(() => {
          this.showSentModal = true;
        });
      }

    }
  },
  async mounted() {
    await this.$store.dispatch('admin/talqahBusiness/cities/get');
    this.cities = this.getCities.map((city) => {
      return { id: city.id, name: city.name_ar }
    });
    // console.log("getCities", this.getCities);
    // console.log("cities", this.cities);
  }

}
;
</script>

<style scoped>
.imageHolder {
  z-index: 99;
}
</style>
